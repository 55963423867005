import React, { useState } from 'react';
import { Button, TextField, Container, Typography, Box, CircularProgress, InputAdornment } from '@mui/material';
import { useDispatch } from 'react-redux';
import { LogIn, verifyOtp } from '../redux/actions/authActions';
import { useNavigate } from 'react-router-dom';
import ReactCountryFlag from 'react-country-flag';

const Login = () => {
  const [step, setStep] = useState('number'); // 'number' or 'otp'
  const [mobileNumber, setMobileNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [referenceId, setReferenceId] = useState('');
  const [tenant, setTenant] = useState('');


  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmitNumber = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await dispatch(LogIn({ mobile: mobileNumber, tenant: "rajoffset" }));
      console.log('LogIn response:', response);
      if (response && response.data && response.data.RefId) {
        setMobileNumber(response?.data?.mobile);

        setReferenceId(response?.data?.RefId);
        setTenant(response?.data?.tenant);
        setStep('otp');
      } else {
        setError('Please refresh your browser.');
      }
    } catch (err) {
      console.error('Error in LogIn:', err);
      setError('Failed to send OTP. Please try again.');
    } finally {
      setLoading(false)
    }
  };

  const handleSubmitOtp = async () => {
    setLoading(true);
    setError('');
    const otpData = { OTP: otp, RefId: referenceId, mobile: mobileNumber, tenant }
    try {
      const response = await dispatch(verifyOtp(otpData));
      console.log('verifyOtp response:', response);
      if (response && response.status === 'success') {
        navigate('/dashboard/my-cards');
      } else {
        setError('OTP verification failed. Please try again.');
      }
    } catch (err) {
      setError('OTP verification failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleMobileNumber = () => {

  }

  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 8 }}>
        <Typography component="h1" variant="h5">
          {step === 'number' ? 'Login' : 'Verify OTP'}
        </Typography>
        <Box sx={{ mt: 1 }}>
          {step === 'number' ? (
            <>
              <TextField
                margin="normal"
                required
                fullWidth
                id="mobileNumber"
                label="Mobile Number"
                name="mobileNumber"
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
                autoComplete="mobile"
                autoFocus
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position='start'
                    >
                      <img
                        src="https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg"
                        alt="Indian Flag"
                        style={{ width: '24px', height: '16px', marginRight: '8px' }}
                      />
                      <span>+91</span>

                    </InputAdornment>
                  )
                }}
              />
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleSubmitNumber}
                disabled={mobileNumber.length !== 10 || isNaN(mobileNumber)}
                sx={{ marginBottom: '100px' }}
              >
                {loading ? <CircularProgress size={24} /> : 'Sign-in to RajOffset'}
              </Button>
            </>
          ) : (
            <>
              <TextField
                margin="normal"
                required
                fullWidth
                id="otp"
                label="OTP"
                name="otp"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                autoComplete="otp"
                autoFocus
              />
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleSubmitOtp}
                disabled={loading}
                sx={{ marginBottom: '100px' }}
              >
                {loading ? <CircularProgress size={24} /> : 'Sign in with OTP'}
              </Button>
            </>
          )}
          {error && <Typography color="error">{error}</Typography>}
        </Box>
      </Box>
    </Container>
  );
};

export default Login;
